export default {
  createPagesFromArray(items, pageSize) {
    let pageDummy = []
    const pages = []
    const totalItems = items.length
    items.forEach((item, index) => {
      pageDummy.push(item)
      if (pageDummy.length === pageSize || index + 1 === totalItems) {
        pages.push(pageDummy)
        pageDummy = []
      }
    })
    return pages
  },
}
